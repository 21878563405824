import en from './locale/en.json'
import de from './locale/de.json'
import se from './locale/se.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de',
  messages: {
    en,
    se,
    de,
  },
}))
